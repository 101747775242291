import React from "react";

import "./styles.css";


import FormSurvey from "./components/FormSurvey";

function Survey() {

  return (
    <div className="body-container ">
        <FormSurvey />
    </div>
  );
}

export default Survey;

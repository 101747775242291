import React from "react";
import ReactDOM from "react-dom";
//import "./index.css";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import Dashboard from "./modules/Admin";
import Survey from "./modules/Survey"; 
import Notfound from "./modules/Notfound";
import Login from "./modules/Admin/views/Login";


ReactDOM.render(
  <BrowserRouter> 
    <Switch>
      {/* <Route path="/admin" component={Dashboard} exact />
      <Route path="/auth" component={Dashboard}  /> */}
      <Route path="/:token_chat" component={Survey}/>  
        
      {/* <Route path="/not_found" component={Notfound} />
      <Route path="/login" component={Login} /> */}
      <Redirect from="/" to="/not_found" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

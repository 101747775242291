 import axios from "axios";


const api = axios.create({
  baseURL: "https://administracao.hopchat.com.br/api/",

}); 






export default api;

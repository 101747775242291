import React, { useState, useEffect } from "react";

import { useParams } from "react-router";

import api from "../../../services/api";

import Logo from "../images/logo.png";

function Survey() {
  const { token_chat } = useParams();
  const [count, setCount] = useState(0);
  const [survey, setSurvey] = useState(0);
  const [company, setCompany] = useState([]);
  const [message, setMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmMessage, setConfirmMassage] = useState("");
  const [emojiselect, setEmojiselect] = useState("");
  const [showLoader, setShowLoader] = useState(true);

  // ========== buscando dados na api ==============´

  useEffect(() => {
    setShowLoader(true);

    (async () => {
      try {
        const response = await api.get(
          `surveys/get_survey?token=${token_chat}`
        );

        setCompany(response.data);
        setShowLoader(false);
        if (!hasError) {
          document.getElementById("form-data").style.visibility = "visible";
        }
      } catch (e) {
        setHasError(true);

        setShowLoader(false);
        const serverSideErrors = e.response.data.info;
        setErrorMessage(serverSideErrors);
      }
    })();
  }, [hasError, token_chat]);

  // ============ Contador de caracteres ==============

  useEffect(() => {
    window.onload = () => {
      const desc = document.getElementById("survey");
      if (desc) {
        desc.addEventListener("keypress", function (e) {
          const inputLength = desc.value.length;
          const maxChars = 249;
          if (inputLength <= maxChars) {
            setCount(inputLength + 1);
          }
          if (inputLength >= maxChars) {
            e.preventDefault();
          }
        });
      }
    };
  }, []);

  // ============= Identificando emoji clicado ========

  useEffect(() => {
    function toggleActive() {
      let option = document.querySelectorAll("li.option");
      if (option) {
        for (let i = 0; i < option.length; i++) {
          option[i].addEventListener("click", function () {
            for (let i = 0; i < option.length; i++) {
              if (option[i].classList.contains("active")) {
                option[i].classList.remove("active");
              }
            }
            option[i].classList.add("active");
            setSurvey(JSON.parse(option[i].value));
            setEmojiselect("");
          });
        }
      }
    }
    toggleActive();
  }, [survey]);

  // ==================== Enviando resposta para a api ==========

  const sendingSurvey = async () => {
    if (survey === 0) {
      setEmojiselect("Você precisa selecionar um emoji para continuar.");
      return null;
    }
    if (hasError === false) {
      document.getElementById("form-data").style.visibility = "hidden";
    }
    try {
      await api.put(
        "surveys/atualizar_survey/",
        {
          resposta: survey,
          token: token_chat,
          descricao: message,
        },
        {
          // headers: {
          //   // Authorization: "Token f0a8434fe4cfd01c3e9dc0f32a5f78ed76714bef",
          // },
        }
      );

      setConfirmMassage("Avaliação cadastrada com sucesso. Obrigado!");
    } catch (e) {
      setHasError(true);
      if (hasError) {
        document.getElementById("form-data").style.visibility = "hidden";
      }
      const serverSideErrors = e.response.data.info;
      setErrorMessage(serverSideErrors);
    }
  };

  return (
    <>
      <strong>{errorMessage}</strong>
      <h6>{confirmMessage}</h6>
      <section className="header">
        <img src={Logo} alt="logo" className="logo" />
        {showLoader ? (
          " "
        ) : (
          <h2>Bem-vindo à pesquisa de satisfação {company.nome_empresa}</h2>
        )}
      </section>
      <section className="form-control-b">
        <div className="form-data" id="form-data">
          <h4>
            Por favor, avalie o atendimento realizado por:{" "}
            <span>{company.agente_chat}</span>
          </h4>
          <br />
          <h3>
            Selecione um emoji de acordo com a sua satisfação no atendimento.
          </h3>
          <ul className="emojis-control">
            <li className="option" value={5}>
              😁
              <br />
            </li>
            <li className="option" value={4}>
              🙂
              <br />
            </li>
            <li className="option" value={3}>
              😐
              <br />
            </li>
            <li className="option" value={2}>
              😕
              <br />
            </li>

            <li className="option" value={1}>
              😡
              <br />
            </li>
          </ul>
          <p>{emojiselect}</p>

          <textarea
            name="survey"
            id="survey"
            maxLength={250}
            value={message.target}
            onChange={(prevState) => setMessage(prevState.target.value)}
            placeholder="Conte mais sobre sua experiência com nosso atendimento? (opcional)"
          />
          <div className="count">Número de caracteres: {count} de 250.</div>
          <button onClick={() => sendingSurvey()}>Enviar</button>
        </div>
      </section>
    </>
  );
}

export default Survey;
